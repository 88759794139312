<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { GetApiActionWithAuthorization } from "../../../helpers/apiActions";
import moment from 'moment-timezone';
import { common } from "../../../mixins/common";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Dashboard",
      items: [],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      fetchingStats: true,
      earningStatus: true,
      applicationFormInline: {
        appId: 14,
        sourceId: 'v1.cybershop.app',
        buyerState: ''
      },
      buyerStates: [],
      storeSales: [],
      storeSaleFiels: [
        {
          key: "id",
          value: '',
          sortable: false,
        },
        {
          key: "totalAmount",
          value: 'Total Amount',
          sortable: false,
          center: true
        },
        {
          key: "date",
          value: 'Date',
          sortable: false,
          center: true
        },
        {
          key: "buyer",
          value: 'Buyer',
          sortable: false,
          center: true
        },
        {
          key: "location",
          value: 'Location',
          sortable: false,
          center: true
        },
      ]
    };
  },
  mixins: [
    common
  ],
  mounted() {
    let _vm = this
    _vm.loadStoreSales()
    _vm.loadStoreStates()
  },
  methods: {
    loadStoreStates() {
      let _vm = this
      return GetApiActionWithAuthorization("admin/orders/load-store-buyer-states/", _vm.applicationFormInline).then(res => {
        if(res.status == "success") {
          _vm.buyerStates = res.data
        }
      });
    },
    loadStoreSales() {
      let _vm = this
      return GetApiActionWithAuthorization("admin/orders/load-store-sales/", _vm.applicationFormInline).then(res => {
        if(res.status == "success") {
          _vm.storeSales = res.data
        }
      });
    },
    getFormattedData(timestamp) {
      return moment(timestamp*1000).format('YYYY-MM-DD h:mm a')
    },
    getTotalSalesAmount() {
      return this.storeSales.reduce((prev, curr) => {
                      let amount = !isNaN(curr.totalPaid) ? parseInt(curr.totalPaid) : 0
                      return prev + amount;
                    }, 0)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div
        class="col-lg-12"
        id="store-sales-widget"
        v-if="loggedUser.groups.includes('admin')">
        <div class="card" style="border: 1px solid #5b5f70">
          <div class="card-body">
            <h4 class="card-title mb-1">Store Sales</h4>
            <el-divider class="my-1 mb-2"></el-divider>
              <div class="text-right">
                  <el-form
                    :inline="true"
                    :model="applicationFormInline"
                    class="form-inline">
                    <!--el-form-item label="Source:" class="mb-0">
                      <el-select
                        v-model="applicationFormInline.sourceId"
                        placeholder="Source">
                        <el-option
                          label="old.cybershop.app"
                          value="old.cybershop.app"></el-option>
                        <el-option
                          label="v1.cybershop.app"
                          value="v1.cybershop.app"></el-option>
                      </el-select>
                    </el-form-item-->
                    <el-form-item label="Application:" class="mb-0">
                      <el-select
                        v-model="applicationFormInline.appId"
                        no-data-text=" "
                        placeholder="Application"
                        @change="loadStoreSales()">
                        <el-option label="Jewel Giant" :value="14"></el-option>
                        <el-option label="NCM Store" :value="15"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Buyer State:" class="mb-0">
                      <el-select
                        v-model="applicationFormInline.buyerState"
                        no-data-text=" "
                        placeholder="Buyer State"
                        @change="loadStoreSales()">
                        <el-option
                          v-for="(state, index) in buyerStates"
                          :key="index"
                          :label="state.name"
                          :value="state.abbreviation"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--el-form-item label="" class="mb-0">
                      <el-dropdown split-button type="success">
                          Export
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>CSV</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-form-item-->
                  </el-form>
              </div>
              <el-divider class="my-3"><i class="fas fa-chart-bar"></i></el-divider>
              <div class="data-table">
                <div class="table-responsive">
                  <div class="text-right" style="font-size: 16px">Total: <strong>${{ getTotalSalesAmount() }}</strong></div>
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="10"
                    class="table b-table"
                    style="background-color: rgba(0, 0, 0, 0.05); border: 5px solid #5b5f70"
                  >
                    <thead
                      role="rowgroup"
                      class=""
                      style="background-color: #74788d; color: white"
                    >
                      <tr role="row" class="">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          :class="[
                            'px-4'
                          ]"
                          v-for="field in storeSaleFiels"
                          :key="field.key"
                        >
                          <div>{{ field.value }}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        aria-rowindex="1"
                        class=""
                        v-for="data in storeSales"
                        :key="data.id"
                      >
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="font-size: 14px;font-weight: bold">
                          {{ data.systemOrderId }}
                        </td>
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="font-size: 14px">
                          ${{ data.totalPaid }}
                        </td>
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="font-size: 14px">
                          {{ getFormattedData(data.createdDate) }}
                        </td>
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="font-size: 14px">
                          {{ data.buyer.firstname }} {{ data.buyer.lastname }}
                        </td>
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="font-size: 14px">
                          <span v-html="data.OrderShippingDetails[0] ? data.OrderShippingDetails[0].address + ', ' +data.OrderShippingDetails[0].state + ', ' + data.OrderShippingDetails[0].city + ', ' + data.OrderShippingDetails[0].postalCode : ''"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
